.more_dropdown ul .list-item .header_item_link:hover{
    background: rgba(235, 233, 233, 0.959);
}

.category_header{
    position: fixed;
    top: 110px;
    width: 100%;
    z-index: 900;
    text-align: center;
    min-height: 60px;
    box-shadow: rgb(0 0 0 / 7%) 0px 0px 10px 0px;    
}

.category_header div ul{
    padding-bottom: 5px !important;
}

.category_header div ul li{
    float: left;
}


.active_div{
    border-bottom: 3.3px solid green;
    background-color: transparent !important;
}

.dropdown_custom_menu{
    height: 220px;
    overflow: scroll;
}

.product_cat_wise_main{
    margin-top: 155px;
}

/* tab--- */    
@media screen and (max-width:900px){
    .category_header{
        position: relative;
        top: 140px !important;
        width: 100%;
        z-index: 900;
        height: 65px;
        overflow: hidden;
        overflow-x: scroll;
    }
    .category_header > div{
        width: 2000px;
        max-width: 1100px;
        overflow: hidden;
        overflow-x: auto;
    }
}
@media screen and (max-width:768px)
{
    
    .category_header > div
    {
        justify-content: left !important;
    }
}

