
.singleSlider .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item{
    height: 500px;  
    border-radius: 22px !important;
}

.full-width-slider.homepage .singleSlider .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item{
    height: auto;  
    border-radius: 0px !important;

}
.singleSlider{
    border-radius: 30px;
}

.singleSlider .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item img{
    background-origin: padding-box;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-size: cover !important;
}

.singleSlider .react-multi-carousel-list .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    z-index: 100;
    right:0 !important;
}

.singleSlider .react-multi-carousel-list .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
    z-index: 100;
    left: 0 !important;
}

.singleSlider .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item img{
    border-radius: 22px !important;
}

/* mobile---------- */
@media screen and (max-width:500px){
    .singleSlider .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item{
        height: 175px !important;
        border-radius: 22px !important;
    }
}

/* tab------------ */

@media screen and (min-width:500px) and (max-width:900px){
    .singleSlider .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item{
        height: 318px !important;
        border-radius: 22px !important;
    }
}